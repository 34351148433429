@import url('https://fonts.cdnfonts.com/css/athene');
@import url('https://fonts.cdnfonts.com/css/minion-pro');

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  overflow: scroll !important;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit;
}

img {
  border-style: solid;
}

button {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

button {
  background: transparent;
  padding: 0;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

*,
:after,
:before {
  box-sizing: inherit;
  border: 0 solid rgba(0, 100, 0, 0.35);
}

h1,
h2,
h3,
h4,
p,
ul {
  margin: 0;
}

ul:not(.unstyled-list) {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
}

iframe,
img {
  display: block;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

.css-1c9a4c6 {
  background-color: #ffffff;
  -webkit-background-position: top center;
  background-position: top center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: -1;
  top: 56px;
  bottom: -120px;
}

@media (min-width: 768px) {
  .css-1c9a4c6 {
    top: 0;
  }
}

.css-1abgt8j {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-xdcd7r {
  height: 56px;
  width: 100%;
}

@media (min-width: 768px) {
  .css-xdcd7r {
    display: none;
  }
}

.css-1swc5nz {
  max-width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .css-1swc5nz {
    display: none;
  }
}

.css-1ago99h {
  width: 100%;
}

.css-mj00yc {
  padding: 0 4% 10px 4%;
  text-align: center;
  z-index: 1;
}

@media (min-width: 768px) {
  .css-mj00yc {
    display: none;
  }
}

.css-1bxc5m3 {
  line-height: 1;
  margin: 8px 0;
  font-family: Athene;
  color: #336733;
  font-size: 70px;
  font-weight: normal;
}

@media (max-width: 640px) {
  .css-1bxc5m3 {
    font-size: calc(60px * 0.75);
  }
}

.css-149qu95 {
  line-height: 1;
  margin: 8px 0;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media (max-width: 640px) {
  .css-149qu95 {
    font-size: calc(25px * 0.75);
  }
}

.css-149qu96 {
  line-height: 1;
  margin: 8px 0;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
}

@media (max-width: 640px) {
  .css-149qu96 {
    font-size: calc(20px * 0.75);
  }
}

.css-as6t7l {
  max-width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
  width: 100%;
  display: none;
}

@media (min-width: 768px) {
  .css-as6t7l {
    display: block;
  }
}

.css-ou2xl5 {
  padding: 0 4% 10px 4%;
  text-align: center;
  z-index: 1;
  display: none;
}

@media (min-width: 768px) {
  .css-ou2xl5 {
    display: block;
  }
}

.css-1rf9dig {
  display: block;
  height: 40px;
  left: 8px;
  margin: 0;
  overflow: hidden;
  position: fixed;
  top: 8px;
  width: 40px;
  z-index: 19;
}

@media (min-width: 768px) {
  .css-1rf9dig {
    display: none;
  }
}

.css-bcsx0a {
  background: none;
  border: none;
  font-size: 10px;
  height: 40px;
  width: 40px;
  border-radius: 3px;
}

.css-17h8xdu::before,
.css-17h8xdu::after {
  content: '';
  height: 3px;
  left: 20%;
  margin: 0 auto;
  border-radius: 3px;
  margin-top: -0.15em;
  position: absolute;
  top: 50%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 24px;
  background: #3e6d2d;
  box-shadow: 0 -10px 0 0 #3e6d2d, 0 10px 0 0 #3e6d2d;
}

.css-1wkn33 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.css-2z2po7 {
  height: 56px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 18;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .css-2z2po7 {
    display: none;
  }
}

.css-1iffxqw {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 5px;
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .css-1iffxqw {
    font-size: calc(32px * 0.75);
  }
}

.css-1htxqsx {
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  z-index: 18;
  background-color: #ffffff;
  height: 0;
}

@media (min-width: 768px) {
  .css-1htxqsx {
    display: none;
  }
}

.css-10fevb3 {
  left: 0px;
  overflow: hidden;
  position: fixed;
  top: 0px;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 18;
  background-color: rgb(255, 255, 255);
  height: 100%;
}

@media (min-width: 768px) {
  .css-10fevb3 {
    display: none;
  }
}

.css-nfosgx {
  padding-top: 56px;
  height: 100vh;
  overflow-y: auto;
}

.css-kxffhl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.css-l698wh {
  text-align: center;
  width: 100%;
  padding: 8px;
}

.css-l698wh:hover,
.css-l698wh:focus {
  background-color: rgba(86, 84, 88, 10%);
}

.css-12he7qw {
  display: block;
  padding: 0;
  margin: 0;
}

.css-1b16ice {
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.25px;
  border-bottom: 2px solid transparent;
  display: block;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0 auto;
  padding: 8px 8px 6px;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid #3e6d2d;
}

@media (max-width: 640px) {
  .css-1b16ice {
    font-size: calc(16px * 0.875);
  }
}

.css-1d1dv5n {
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
  border-bottom: 2px solid transparent;
  display: block;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0 auto;
  padding: 8px 8px 6px;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 640px) {
  .css-1d1dv5n {
    font-size: calc(16px * 0.875);
  }
}

.css-o8h4rm {
  display: none;
}

@media (min-width: 768px) {
  .css-o8h4rm {
    display: block;
  }
}

.p-t-20 {
  padding-top: 20px;
}

.css-1bpqnod {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0;
  padding: 8px 16px;
}

.css-16kcklx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.css-haue6f {
  display: inline-block;
  list-style: none;
  padding: 0 8px;
}

.css-1x9swe2 {
  display: block;
  padding: 0;
  margin: 0;
  margin: 0;
  padding: 8px 0;
}

.css-kheh8y {
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
  border-bottom: 2px solid transparent;
  display: block;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0 auto;
  padding: 8px 8px 6px;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 640px) {
  .css-kheh8y {
    font-size: calc(16px * 0.875);
  }
}

.css-kheh8y:hover {
  color: #3e6d2d;
  border-bottom: 2px solid #3e6d2db3;
}

.css-iex2id {
  padding: 16px 0;
  width: 100%;
}

.css-15q4awx {
  letter-spacing: 2.23px;
  line-height: 1.3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 12px auto;
  padding: 0 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 5px;
  text-transform: uppercase;
}

@media (min-width: 640px) {
  .css-15q4awx {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 52px auto 20px;
  }
}

@media (max-width: 640px) {
  .css-15q4awx {
    font-size: calc(42px * 0.75);
  }
}

.css-1a823kb {
  padding: 0 36px 20px;
  border-bottom: 1px solid #3e6d2d;
}

@media (min-width: 640px) {
  .css-1a823kb {
    border-bottom: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 72px 20px 0;
    width: 315px;
    border-right: 1px solid #3e6d2d;
  }
}

.css-1yltisf {
  text-align: center;
}

.css-1jqzgsk {
  padding: 20px 36px 0;
}

@media (min-width: 640px) {
  .css-1jqzgsk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0 20px 72px;
    width: 315px;
  }
}

.css-xtb3p0 {
  margin: auto;
  max-width: 720px;
  padding: 0 20px;
}

@media (min-width: 720px) {
  .css-xtb3p0 {
    padding: 0;
  }
}

.css-1gcbltk {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 48px 0 24px;
  text-align: center;
}

.css-15r1rym {
  margin: 8px 0;
}

.css-1w00nmr {
  line-height: 1;
  margin: 32px 0 8px 0;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 5px;
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .css-1w00nmr {
    font-size: calc(32px * 0.75);
  }
}

.css-15mgkk6 {
  margin: 8px 0;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media (max-width: 640px) {
  .css-15mgkk6 {
    font-size: calc(24px * 0.75);
  }
}

.css-1f79j9i {
  padding: 48px 0 40px;
}

@media (min-width: 768px) {
  .css-1f79j9i {
    display: none;
  }
}

.css-19g66ca {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid rgba(86, 84, 88, 45%);
}

@media (min-width: 768px) {
  .css-19g66ca {
    display: none;
  }
}

.css-1u1vvf2 {
  text-align: center;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid rgba(86, 84, 88, 45%);
}

.css-1u1vvf2:hover,
.css-1u1vvf2:focus {
  background-color: rgba(86, 84, 88, 10%);
}

.css-rzmzie {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-top: auto;
  position: relative;
  width: 100%;
}

.css-10xb0p9 {
  letter-spacing: 3.4px;
  line-height: 1;
  margin: 52px auto;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.css-fpza88 {
  padding: 0 18px 16px;
  font-family: Athene;
  color: #336733;
  font-size: 70px;
  font-weight: normal;
}

@media (max-width: 640px) {
  .css-fpza88 {
    font-size: calc(60px * 0.75);
  }
}

.css-b4drd9 {
  padding: 20px 18px 0;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 4px;
  border-top: 1px solid #336733;
}

@media (max-width: 640px) {
  .css-b4drd9 {
    font-size: calc(20px * 0.75);
  }
}

.css-1yrcy90 {
  line-height: 1.75;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 12px 0;
  text-align: center;
}

@media (max-width: 640px) {
  .css-1yrcy90 {
    font-size: calc(12px * 0.8333);
  }
}

.css-uewej7 {
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-decoration: underline;
}

@media (max-width: 640px) {
  .css-uewej7 {
    font-size: calc(12px * 0.8333);
  }
}

#survicate-box * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Open sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  outline: none;
}

.css-ewxgru {
  margin: 44px 0px 0px;
  text-align: center;
  font-family: 'Minion Pro';
  color: rgb(86, 84, 88);
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 5px;
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .css-ewxgru {
    font-size: calc(24px);
  }
}

@media (max-width: 768px) {
  .css-ewxgru {
    padding: 0px 16px;
  }
}

.css-5d390f::before,
.css-5d390f::after {
  content: '';
  height: 3px;
  left: 20%;
  margin: -0.15em auto 0px;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  transition: all 0.4s ease 0s;
  width: 24px;
  background: rgb(86, 84, 88);
  box-shadow: rgb(86, 84, 88) 0px -10px 0px 0px,
    rgb(86, 84, 88) 0px 10px 0px 0px;
}

.css-5d390f::after {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  transform: rotateZ(45deg);
}

.css-5d390f::before {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  transform: rotateZ(-45deg);
}

.css-mx8zre {
  margin: 0 auto;
  padding: 15px;
}

@media (min-width: 540px) {
  .css-mx8zre {
    max-width: 540px;
  }
}

.css-huenw5 {
  padding: 0;
  margin: 1.1em auto;
  max-width: 550px;
  text-align: left;
  font-family: Minion Pro;
  color: #3e6d2d;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

@media (max-width: 768px) {
  .css-huenw5 {
    padding: 0 16px;
  }
}

@media (max-width: 640px) {
  .css-huenw5 {
    font-size: calc(16px * 0.875);
  }
}

.ql-align-center {
  text-align: center;
}

p {
  white-space: pre-line;
}

.map-item {
  position: relative;
}

.map-item picture {
  position: relative;
  width: 100%;
}

.copyright-text {
  margin-left: 15px;
}

.svg-inline--fa {
  margin: 0 5px;
}

.horizontal-break {
  border: 1px solid rgba(0, 100, 0, 0.35);
}

@media (max-width: 640px) {
  .horizontal-break {
    margin: 0 16px;
  }
}

.countdown-timer-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  width: 55px;
  margin-bottom: 20px;
}

.countdown-timer-txt1 {
  font-family: Minion Pro;
  font-size: 30px;
  color: #3e6d2d;
  line-height: 1;
}

.countdown-timer-txt2 {
  font-family: Minion Pro;
  font-size: 16px;
  color: #3e6d2d;
  line-height: 1;
}

.countdown-timer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 42px 50px 22px 50px;
  border: 2px solid rgba(0, 100, 0, 0.35);
}

@media (max-width: 768px) {
  .countdown-timer-container {
    padding: 34px 40px 17px 40px;
  }
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-4 {
  padding-bottom: 4px;
}

.photo-appear {
  opacity: 0;
}

.photo-appear-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.photo-enter {
  opacity: 0;
}

.photo-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.photo-exit {
  opacity: 1;
}

.photo-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

.picture-wrapper {
  position: relative;
  text-align: left;
  width: 100%;
}

.picture-wrapper picture {
  position: absolute;
}

.disclaimer-itinerary-image-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.disclaimer-itinerary-image {
  padding: 15px;
  border: 1px solid #3e6d2d;
}

.css-o6iq6k {
  padding: 1px 20px;
  max-width: 550px;
  margin: 0px auto;
  text-align: center;
}

.css-vc6ig9 {
  line-height: 1;
  margin: 28px 0px;
  font-family: 'Minion Pro';
  color: rgb(86, 84, 88);
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 5px;
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .css-vc6ig9 {
    font-size: calc(32px * 0.66);
  }
}

.css-16e4f55 {
  margin: 16px 0px;
}

.css-xsuxm9 {
  padding: 0px;
  max-width: 550px;
  text-align: left;
  line-height: 1.75;
  margin-top: 16px;
  font-family: 'Minion Pro';
  color: rgb(86, 84, 88);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

@media (max-width: 768px) {
  .css-xsuxm9 {
    padding: 0 16px;
  }
}

@media (max-width: 640px) {
  .css-xsuxm9 {
    font-size: calc(16px * 0.875);
  }
}

.css-10u2vbj {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 15px 0px;
}

.css-1iiedfe {
  margin: 1rem auto 0px;
}

.css-3t106u {
  font-style: normal;
  line-height: 1;
  font-family: 'Minion Pro';
  color: rgb(86, 84, 88);
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media (max-width: 640px) {
  .css-3t106u {
    font-size: calc(20px * 0.75);
  }
}

.css-1e2iawv {
  font-style: normal;
  line-height: 1;
  margin-top: 8px;
  font-family: 'Minion Pro';
  color: rgb(86, 84, 88);
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media (max-width: 640px) {
  .css-1e2iawv {
    font-size: calc(20px * 0.75);
  }
}

.css-aqtsjn {
  line-height: 1.25;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 10px;
  padding: 13px 36px;
  text-decoration: none;
  transition: all 0.25s ease 0s;
  font-family: 'Minion Pro';
  font-size: 16px;
  font-weight: 400;
  background-color: #336733;
  color: rgb(255, 255, 255);
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

.css-aqtsjn:hover {
  background-color: #527952;
  color: rgb(255, 255, 255);
}

@media (max-width: 640px) {
  .css-aqtsjn {
    font-size: calc(16px * 0.75);
  }
}

.background-activity-image-container {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.background-activity-inner-image-container {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}

.background-activity-image {
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.activity-image {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.small-title {
  line-height: 1;
  margin: 21px 0px;
  font-family: 'Minion Pro';
  color: rgb(86, 84, 88);
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 640px) {
  .small-title {
    font-size: calc(24px * 0.75);
    font-weight: 600;
  }
}

.superscript-info {
  margin-top: 5px;
  font-size: 12px;
}

.clipboard-field-container {
  display: flex;
  justify-content: center;
  margin: 2px;
}

.clipboard-field {
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: #d3d3d3;
  border-radius: 8px;
}

.clipboard-field-text {
  padding: 4px 8px;
}

.clipboard-field-button {
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  padding: 4px;
  background-color: #336733;
  color: rgb(255, 255, 255);
  letter-spacing: 0.5px;
  width: 75px;
}

.clipboard-field-button:hover, .clipboard-field-button.copied {
  background-color: #527952;
  color: rgb(255, 255, 255);
  border: 1px solid #336733;
  padding: 3px 7px;
}

@media (max-width: 640px) {
  .clipboard-field-button {
    width: 68px;
  }
}

a.markdown-anchor {
  text-decoration: underline;
  font-weight: bold;
}

.contact {
  font-weight: bold;
}
